(function($){

  // ---------- Header START
  $.widget('saipol.header', {

    "_create": function(){
      if($('#nav')) this._initNav();
    },

    "_initNav": function(){
      var toggler 		= $('#js-nav-toggler'),
        childnav 		= $('.has-child'),
        menu_items 	= $('.nav-menu__items'),
        menu_container	= $('.nav-menu__wrapper'),
        menu_close			= $('.fs-menu__close'),
        current_item 		= null;

      //$('#header .nav-secondary__languages').clone().appendTo($('#nav .nav__right'));

      childnav.on('click', toggleSubNav);
      menu_close.on('click', closeNav);
      toggler.on( "click", function() {
        current_item = false;
        toggleNav(current_item);
      });

      function toggleSubNav(){
        var el = $('#menu' + $(this).attr('data-id'));
        var elID = el.attr('id');
        childnav.removeClass('active');
        menu_items.removeClass('active');
        $(this).addClass('active');
        $(el).addClass('active');
        toggleNav(elID);
        current_item = elID;
      }

      function toggleNav(el){
        if($('body').hasClass('is-overlayed')) {
          if(el === current_item)
            closeNav();
        } else {
            openNav();
        }
      }

      function openNav(){
        $('body').addClass('is-overlayed');
        $(window).on('keyup', listenEscKey);
      }

      function closeNav(){
        $('body').removeClass('is-overlayed');
        childnav.removeClass('active');
        menu_items.removeClass('active');
        $(window).off('keyup', listenEscKey);
      }

      function listenEscKey(e){
        if(e.keyCode === 27) {
          closeNav();
        }
      }
    }

  });
  // ---------- Header END

})(jQuery);
