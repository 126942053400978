(function($){

var page_load = false;
var header_fixed = false;
var header_fixed_after = 180;
var back_to_top;
var back_to_top_after = 300;
var back_to_top_shown = false;
// ---------- APP START
var App = {

  config: {
    mapStyles: [],
		scrollOffset: 100
  },

  init: function(){
    // $.fn.matchHeight._maintainScroll = true;

    // Init SVG polyfill
    if(typeof(svg4everybody) !== 'undefined') svg4everybody();

    // Init object-fill polyfill
    if(typeof(objectFitImages) !== 'undefined') objectFitImages();

    // Remove focus state when clicking on a button with a mouse
    $(':not(form) button, a').on('click', function(e){
      if(e.clientX && e.clientY)
        $(this).blur();
    });

    $('a[href^="#"]').on('click', function(e){
      if ($(this).attr("href") != '#rgpd'){
        e.preventDefault();

        var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');

        if($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
          App.scroll(scrollTo, $(this).data('scroll-offset'));
      }
    });

    // if($('[data-scroll]').length){
    //   App.scroll($('[data-scroll]').data('scroll'));
    // }

    // if($('.header'.length)) $('.header').header();

    back_to_top = $('.back-to-top');
    main_menu_bloc = $('header .bottom-menu');

    App.initButtons();
    $(window).resize(App.initButtons);

    App.initMenuMobile();
    App.initMenuDesktop();
    App.initLangSwitch();
    App.initClickableDiv();
    if($('#content').length) App.initReadingProgress();
    if ($('.sp-slider-home').length) App.initHomeSlider();
    if ($('.key-figures__container').length) App.initKeyFiguresSlider();
    if ($('.sp-home-solution').length) App.initHomeSolutions();
    if ($('.sp-home-commitment').length) App.initHomeCommitments();
    if ($('.sp-slider-images__container').length) App.initImagesSlider();
    if ($('.history-slider').length) App.initHistorySlider();
    if ($('.news-filters').length) App.initFilters(true);
    if ($('.jobs-filters').length) App.initFilters(false);
    if ($('.text-image-slide-container').length) App.initTextImageSlider();
    if ($('.our-sites').length) App.initOurSites();
    if ($('.sp-gov-mem').length) App.initGovernance();
    if ($('.retractable').length) App.initRetractable();

    var accessibility = App.readCookie('accessibility');
    if (accessibility == 'on'){
      $('html').addClass('m--accessibility');
    }
    $('.btn-access').on('click', App.accessibility);

    back_to_top.on('click', function(e){
      e.preventDefault();
      $('html,body').animate({scrollTop: 0}, 700);
    });

    $(document).on('click', '.video-embed a', function(e) {
      var $this = $(this);
      $this.stop(1,1).fadeOut(200, function() {
        $this.parent().html('<div class="video-embed__wrapper"><iframe src="https://www.youtube-nocookie.com/embed/'+$(this).data('video-embed')+'?autoplay=1" frameborder="0" allowfullscreen></iframe></div>');
      });
    });

    App.initTAC();
    App.initGTAG();

  },

  load: function(){
    page_load = true;
    if (window.location.hash && $(window.location.hash).length) App.scroll(window.location.hash, 100);
    if ($('.nf-form-cont').length){
      App.initFormSubmit();
    }


  },

  scroll: function(element, offset){
    offset = offset === undefined ? App.config.scrollOffset : offset;
    $('html, body').animate({ scrollTop: $(element).offset().top - offset}, 'slow');
  },

  // Menu
  initMenuMobile: function(){
    $('.bottom-menu__burger').on('click', function(){
      if ($('body').hasClass('menu-is-opened')){
        $('body').removeClass('menu-is-opened');
        setTimeout(function(){$('.m--is-opened').removeClass('m--is-opened');}, 500);
      }
      else{
        $('body').addClass('menu-is-opened');
      }
    });

    $('.menu__search').on('click', function(){
      $('.header').toggleClass('search-is-opened');
      if ($('body').hasClass('search-is-opened')){
        $('body').removeClass('search-is-opened');
      }
      else{
        $('body').addClass('search-is-opened');
      }
    });
    $('.bottom-menu__search-container-close').on('click', function(){
      $('.menu__search').first().trigger('click');
    });


    $('.bottom-menu__sub-menu-container').each(function(){
      $(this).prepend('<button class="bottom-menu__back">'+$(this).siblings('.bottom-menu__label').first().text()+'</button>');
    });

    $('.bottom-menu__lvl-0.menu-item-has-children').on('click', function(){
      $(this).find('.bottom-menu__sub-menu-container').addClass('m--is-opened');
    });

    $('.bottom-menu__back').on('click', function(e){
      $(this).parent().parent().removeClass('m--is-opened');
      e.stopPropagation();
    });

  },

  initMenuDesktop: function(){

    $('.bottom-menu__lvl-0.menu-item-has-children').on('click', function(){
      $current_item = $(this);
      $('.m--is-opened').removeClass('m--is-opened');
      $current_item.addClass('m--is-opened');
      $sub_menu_container = $('.bottom-menu__menu-opened-container');
      $sub_menu_container.addClass('m--is-opened');
      $sub_menu_container.find('.bottom-menu__moc-label').text($current_item.find('.bottom-menu__label').first().text());
      $sub_menu_container.find('.bottom-menu__moc-desc').text($current_item.find('.bottom-menu__description').first().text());
      $sub_menu_container.find('.bottom-menu__moc-sub-menu').html($current_item.find('.bottom-menu__sub-menu').first().html());
      $('body').addClass('menu-is-opened');
      window.App.unfixHeader();

    });

    $('.bottom-menu__moc-close').on('click', function(e){
      $('.m--is-opened').removeClass('m--is-opened');
      $('body').removeClass('menu-is-opened');
      if (App.s>header_fixed_after && !header_fixed) {
        window.App.fixHeader();
      }
      e.stopPropagation();
    });

    $(window).on('scroll', function() {

      App.s = $(window).scrollTop();

      if (page_load) {
        if (App.s>header_fixed_after && !header_fixed) {
          if (! $('body').hasClass('menu-is-opened')){
            window.App.fixHeader();
          }
        } else if (App.s<=header_fixed_after && header_fixed) {
          window.App.unfixHeader();
        }

        if (App.s>back_to_top_after && !back_to_top_shown){
          window.App.showBackToTop();
        } else if (App.s<=back_to_top_after && back_to_top_shown) {
          window.App.hideBackToTop();
        }
      }
    });

  },

  accessibility: function(){
    var $html = $('html');
    if ($html.hasClass('m--accessibility')){
      $html.removeClass('m--accessibility');
      App.createCookie('accessibility','off', 7);
    }
    else{
      $html.addClass('m--accessibility');
      App.createCookie('accessibility','on', 7);
    }
  },

  createCookie: function(name,value,days) {
    var expires ='';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = "; expires="+date.toGMTString();
    }
    else expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
  },

  readCookie: function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },

  eraseCookie: function(name) {
    createCookie(name,"",-1);
  },

  initHomeSlider: function(){
    $('.sp-slider-home__container').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true,
      autoplaySpeed: 3000,
      dots: true,
      nextArrow:$('.sp-slider-home__arrow.arrow-next'),
      prevArrow:$('.sp-slider-home__arrow.arrow-prev'),
      appendDots: $('.sp-slider-home__dots'),

    });
  },

  initKeyFiguresSlider: function(){
    $('.key-figures__container').each(function(){
      $(this).slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay:true,
        autoplaySpeed: 2000,
        dots: true,
        arrows: false,
        appendDots: $(this).siblings('.slider__dots'),
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    });
  },

  initImagesSlider: function(){
    $('.sp-slider-images__container').on('init', function(slick){
      var numberOfSlides = $(this).find('.slick-slide:not(.slick-cloned)').length;

      $('.sp-slider-images__total-number').text(numberOfSlides);

    });

    $('.sp-slider-images__container').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $('.sp-slider-images__current-number').text(nextSlide+1);
    });

    $('.sp-slider-images__container').each(function(){
      $(this).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:false,
        centerMode: true,
        centerPadding: '30%',
        nextArrow: '<button type="button" class="slick-next"></button>',
        prevArrow: '<button type="button" class="slick-prev"></button>',
        appendArrows:$(this).parent().find('.sp-slider-images__arrows'),
        responsive:[
          {
            breakpoint: 1300,
            settings:{
              centerPadding: '20%',
            }
          },
          {
            breakpoint: 800,
            settings:{
              centerPadding: '10%',
            }
          },
        ]
      });
    });
  },

  initButtons: function(){
    $('.btn').each(function(){
      $(this).css('border-radius',$(this).outerHeight()+'px / 100%');
      $(this).find('.btn__round').width($(this).outerHeight() - 10+'px').css('border-radius',($(this).outerHeight()-10)+'px / 100%');
      $(this).on('mouseenter',
        function(){
          $(this).find('.btn__round').width('calc(100% - 10px)');
        }
      );
      $(this).on('mouseleave',
        function(){
          $(this).find('.btn__round').width($(this).outerHeight() - 10+'px');
        }
      );
    });
  },

  initHomeSolutions: function(){
    $('.sp-home-solution').on('click mouseenter', function(){
      $(this).siblings().removeClass('is-active');
      $(this).addClass('is-active');
    });
    $('.sp-home-solution:first-child()').addClass('is-active');
  },

  initHomeCommitments: function(){
    $('.sp-home-commitment__title').each(function(){

      var $parent = $(this).parents('.sp-home-commitment').first();
      $(this).on('click', function(){
        $parent.toggleClass('m--open');
      });
      $(this).on('mouseover', function(){
        $('.sp-home-commitment.is-active').removeClass('is-active');
        $parent.addClass('is-active');
        $('.sp-home-commitments__area').css('background-image','url('+$parent.find('.sp-home-commitment__image img').attr('src')+')');
        $('.sp-home-commitments__text').html($parent.find('.sp-home-commitment__text').html());
      });

    });

    $('.sp-home-commitment__title').first().trigger('mouseover');
  },

  fixHeader: function() {
    if (typeof main_menu_bloc!='undefined') {
      if (main_menu_bloc.length) {
        header_fixed = true;
        main_menu_bloc.addClass('is--fixed');
      }
    }
  },

  unfixHeader: function () {
    if (typeof main_menu_bloc!='undefined') {
      if (main_menu_bloc.length) {
        header_fixed = false;
        main_menu_bloc.removeClass('is--fixed');
      }
    }
  },

  initReadingProgress: function() {
    var $reading_progress_bar = $('<div class="reading-progress-bar"></div>');
    var $progress = $('<div class="reading-progress-bar__progress"></div>').appendTo($reading_progress_bar);
    $('.footer').append($reading_progress_bar);

    var $content = $('#content');
    $(window).scroll(function(e) {
      window.requestAnimationFrame(function() {
        var bottom_scroll = $(window).scrollTop(); // + $(window).height();
        var content_top = $content.offset().top;
        var content_height = $content.outerHeight() - $(window).height();
        var width = Math.max(0, Math.min(1, (bottom_scroll - content_top) / content_height));
        $progress.css('width', width * 100 +'%');
      });
    });
  },

  showBackToTop: function() {
    if (typeof back_to_top!='undefined') {
      if (back_to_top.length) {
        back_to_top_shown = true;
        back_to_top.addClass('is--visible');
      }
    }
  },

  hideBackToTop: function() {
    if (typeof back_to_top!='undefined') {
      if (back_to_top.length) {
        back_to_top_shown = false;
        back_to_top.removeClass('is--visible');
      }
    }
  },

  initHistorySlider: function(){
    $('.history-slider').each(function(){
      $(this).find('.history-slide-group').each(function(index){
        $(this).data('slide-group',index);
      });
      var $slider_group_container = $('<div class="history-slider__group-container"></div>');
      $slider_group_container.append($('.history-slide-group').first().find('.history-slide-group__interval').clone());
      $slider_group_container.append($('.history-slide-group').first().find('.history-slide-group__title').clone());
      var $slider_container = $('<div class="history-slider__container"></div>');
      $(this).find('.history-slide').each(function(){
        $(this).data('slide-group', $(this).parents('.history-slide-group').data('slide-group')).appendTo($slider_container);
      });
      $(this).append($slider_group_container);
      $(this).append($slider_container);

      var firstDate = $slider_container.find('.history-slide:first-child .history-slide__date').text();
      var lastDate = $slider_container.find('.history-slide:last-child .history-slide__date').text();
      $slider_container.on('init', function(){
        $(this).parent().find('.history-slide-group').css('display', 'none');


        $('.history-slider__firstdate').text(firstDate);
        $('.history-slider__lastdate').text(lastDate);
      });
      $slider_container.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        var $nextSlide = $(this).find("[data-slick-index='" +nextSlide+ "']");

        if ($(this).find("[data-slick-index='" +currentSlide+ "']").data('slide-group') != $nextSlide.data('slide-group') ){
          $(this).parent().find('.history-slide-group').each(function(){
            if ($(this).data('slide-group') == $nextSlide.data('slide-group')){
              $slider_group_container.find('.history-slide-group__interval').html($(this).find('.history-slide-group__interval').html());
              $slider_group_container.find('.history-slide-group__title').html($(this).find('.history-slide-group__title').html());
            }
          });
        }

      });
      var icon = '<svg id="icon-logo-saipol-small" viewBox="0 0 32 32"><path d="M27.35 5.2c-1.428-1.303-3.123-2.341-4.992-3.019l-0.105-0.033c-1.862-0.676-4.011-1.067-6.252-1.067s-4.39 0.391-6.383 1.109l0.131-0.041c-1.974 0.711-3.67 1.749-5.11 3.063l0.012-0.011c-1.423 1.314-2.573 2.901-3.371 4.679l-0.036 0.091c-0.787 1.838-1.244 3.977-1.244 6.223s0.457 4.385 1.284 6.329l-0.040-0.106c0.834 1.87 1.984 3.457 3.398 4.763l0.010 0.009c1.428 1.303 3.124 2.34 4.993 3.018l0.105 0.033c0.658 0.249 1.461 0.483 2.287 0.658l0.113 0.020c-4.534-5.695-7.423-15.11 3.85-22.694 11.272 7.584 8.384 17 3.85 22.694 0.939-0.195 1.742-0.429 2.52-0.717l-0.12 0.039c1.973-0.711 3.669-1.749 5.108-3.062l-0.012 0.011c1.424-1.314 2.574-2.901 3.371-4.681l0.036-0.091c0.788-1.838 1.246-3.977 1.246-6.223s-0.458-4.385-1.286-6.328l0.040 0.106c-0.832-1.869-1.981-3.456-3.394-4.761l-0.010-0.009zM16.114 22.482c0.106-1.409 0.378-2.825 0.604-4.268 0.144-0.639 0.226-1.373 0.226-2.126 0-0.803-0.094-1.585-0.271-2.334l0.014 0.069c-0.495 1.44-1.154 2.815-1.703 4.24-0.546 1.275-0.935 2.753-1.093 4.3l-0.005 0.065c-0.020 0.228-0.032 0.492-0.032 0.76 0 1.334 0.287 2.602 0.803 3.743l-0.023-0.057c0.659 1.416 1.552 2.616 2.641 3.603l0.009 0.008c0.135 0.126 0.47 0.422 0.488 0.434-0.555-1.198-1.042-2.608-1.388-4.072l-0.031-0.155c-0.188-0.881-0.295-1.893-0.295-2.931 0-0.451 0.020-0.896 0.060-1.337l-0.004 0.057z"></path></svg>';
      $slider_container.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: '<button type="button" class="slick-next"></button>',
        prevArrow: '<button type="button" class="slick-prev"></button>',
        appendArrows:$(this).parent().find('.history-slider__arrows'),
        dots: true,
        appendDots: '.history-slider__dots',
        customPaging : function(slider, i) {
          var year = $(slider.$slides[i]).find(".history-slide__date").text();
          var content = $('<button type="button"><div class="slick-inactive-icon__wrapper"><div class="slick-inactive-icon__date">'+ year +'</div><div class="slick-inactive-icon"></div></div><div class="slick-active__icon-wrapper"><div class="slick-active__icon">'+icon+'</div></div></button>');
          return content;
        }
      });
    });
  },

  initLangSwitch: function(){
    $('.lang-switch__current').on('click', function(){
      $(this).parent().toggleClass('m--lang-list-open');
    });
  },

  initClickableDiv: function(){
    $('[data-href]').on('click', function() {
      if ($(this).data('href-target') == '_blank') {
        window.open($(this).data('href'), '_blank');
      } else {
        document.location.href = $(this).data('href');
      }
    }).find('a, button').on('click', function(e) {
      e.stopPropagation();
    });
  },

  initFilters: function(isNews){
    $('.filters__open').on('click', function(){
      $(this).siblings('.filters__container').addClass('m--open');
      $('body').addClass('container-is-opened--mobile');
    });
    $('.filters__container-close').on('click', function(){
      $(this).parents('.filters__container').removeClass('m--open');
      $('body').removeClass('container-is-opened--mobile');
    });
    $('.filters__label').on('click', function(){
      $(this).parents('.filters__filter').toggleClass('m--open');
    });
    $('.filters__option').on('click', function(){
      if (!$(this).hasClass('is--selected')){
        $(this).clone().removeClass('filters__option').addClass('filters__checked-term').appendTo($('.filters__checked'));
        $(this).addClass('is--selected');
        $(this).parents('.filters__filter').removeClass('m--open');
        $('.filters__container-close').trigger('click');
        if (isNews){
          App.reloadNewsAjax();
        }
        else{
          App.filterJobs();
        }
      }
    });
    $('.filters__checked').on('click', '.filters__checked-term', function(e){
      $('.filters__option[data-term-id="'+$(this).data('term-id')+'"]').removeClass('is--selected');
      $(this).remove();
      if (isNews){
        App.reloadNewsAjax();
      }
      else{
        App.filterJobs();
      }
    });
  },

  reloadNewsAjax: function(){
    var themeid = '';
    $('.filters__filter.m--theme').find('.filters__option.is--selected').each(function(){
      themeid += $(this).data('term-id')+',';
    });
    var brandid = '';
    $('.filters__filter.m--brand').find('.filters__option.is--selected').each(function(){
      brandid += $(this).data('term-id')+',';
    });
    var siteid = '';
    $('.filters__filter.m--site').find('.filters__option.is--selected').each(function(){
      siteid += $(this).data('term-id')+',';
    });
    var current_url = window.location.pathname.toString().replace(/\/page\/\d+/, '') ;
    var state = { 'theme': themeid, 'marque': brandid, 'site': siteid };
    var title = '';
    var url = current_url;

    if (themeid !== '' || brandid !== '' || siteid !== ''){
      url += '?';
      if (themeid !== ''){
        url += 'theme='+themeid;
      }
      if (brandid !== ''){
        url += 'marque='+brandid;
      }
      if (siteid !== ''){
        url += 'site='+siteid;
      }
    }

    history.pushState(state, title, url);

    var $loader = $('.news-loader');
    $loader.append($('<div class="loader-ring"><div></div><div></div><div></div><div></div></div>'));
    $loader.css('display', 'block');

    $.ajax({
      url: ajaxurl,
      type: "GET",
      data: {
        'action': 'reload_news',
        pathname: current_url,
        theme : themeid,
        marque: brandid,
        site: siteid,
      }
    }).done(function(response) {
      $('#news-listing-ajax-wrapper').html(response);
      $loader.find('.loader-ring').remove();
      $loader.css('display', 'none');
      $('.filters__nb-res').html($(response).filter('.filters__nb-res-ajax').html());
    });
  },

  filterJobs: function(){
    var functionid = [];
    $('.filters__filter.m--function').find('.filters__option.is--selected').each(function(){
      functionid.push($(this).data('term-id').toString());
    });
    var contractid = [];
    $('.filters__filter.m--contract').find('.filters__option.is--selected').each(function(){
      contractid.push($(this).data('term-id').toString());
    });
    var siteid = [];
    $('.filters__filter.m--site').find('.filters__option.is--selected').each(function(){
      siteid.push($(this).data('term-id').toString());
    });
    var regionid = [];
    $('.filters__filter.m--region').find('.filters__option.is--selected').each(function(){
      regionid.push($(this).data('term-id').toString());
    });

    $('.job-offer').each(function(){
      var function_ok = false;
      if (functionid.length == 0){
        function_ok = true;
      }
      else{
        var cur_function = $(this).data('function').toString().split(',');
        cur_function.forEach(function(value){
          if(functionid.indexOf(value) > -1){
            function_ok = true;
          }
        });
      }

      var contract_ok = false;
      if (contractid.length == 0){
        contract_ok = true;
      }
      else{
        var cur_contract = $(this).data('contract').toString().split(',');
        cur_contract.forEach(function(value){
          if(contractid.indexOf(value) > -1){
            contract_ok = true;
          }
        });
      }

      var site_ok = false;
      if (siteid.length == 0){
        site_ok = true;
      }
      else{
        var cur_site = $(this).data('site').toString().split(',');
        cur_site.forEach(function(value){
          if(siteid.indexOf(value) > -1){
            site_ok = true;
          }
        });
      }

      var region_ok = false;
      if (regionid.length == 0){
        region_ok = true;
      }
      else{
        var cur_region = $(this).data('region').toString().split(',');
        cur_region.forEach(function(value){
          if(regionid.indexOf(value) > -1){
            region_ok = true;
          }
        });
      }

      if (function_ok && contract_ok && site_ok && region_ok){
        $(this).removeClass('is--hidden');
      }
      else{
        $(this).addClass('is--hidden');
      }
    });

    $('.filters__nb-res-value').text($('.job-offer:not(.is--hidden)').length);
  },

  initTextImageSlider: function(){
    $('.text-image-slide-container__slider').each(function(){
      $(this).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed:10000,
        nextArrow: '<button type="button" class="slick-next"></button>',
        prevArrow: '<button type="button" class="slick-prev"></button>',
        appendArrows:$(this).parent().find('.text-image-slide-container__arrows')
      });
    });
  },

  initFormSubmit: function(){
    $('.nf-form-cont').on('click', '.btn', function(){
      $(this).parents('.nf-form-content').find('input[type="button"]').trigger('click');
    });
  },

  initOurSites: function(){

    $('.osl__site').each(function(){
      var id = $(this).attr('id').replace('site-','');
      var $map = $('.our-sites__map').first();
      if ($(this).data('pin-x') != '' && $(this).data('pin-y') != ''){
        $('<div id="sitepin-'+id+'" class="our-sites__pin '+$(this).data('pin-class')+'" style="left:'+$(this).data('pin-x')+'%; top:'+$(this).data('pin-y')+'%; "></div>').appendTo($map);
      }
    });

    var $infos = $('.our-sites__infos');
    var $details = $('.our-sites__details-container');

    $('.osl__site, .our-sites__pin').on('click', function(){
      var id = $(this).attr('id').replace('site-','').replace('sitepin-','');
      $('.our-sites__pin.m--selected').removeClass('m--selected');
      $('.osd__site.m--selected').removeClass('m--selected');
      $('#sitedesc-'+id).addClass('m--selected');
      $('#sitepin-'+id).addClass('m--selected');
      $infos.css('height',$details.height());
      $details.addClass('m--open');
      // $infos.css('max-height', $details.height()).css('height', $details.height());
      App.initButtons(); // Buttons are not initialized correctly as they are in display:none
    });

    $('.our-sites__return').on('click', function(){
      $infos.css('height', $('.our-sites__list').outerHeight(true));
      $details.removeClass('m--open');
      $('.our-sites__pin.m--selected').removeClass('m--selected');
      $('.osd__site.m--selected').removeClass('m--selected');
    });
  },

  initGovernance: function(){
    $('.sp-gov-mem').on('click', function(){
      $('.sp-gov-mem__text-wrapper').css('height', 0);
      if ($(this).hasClass('m--open')){
        $(this).removeClass('m--open');
        // $('.sp-gov-mem__text-wrapper').css('height', 0);
      }
      else{
        $('.sp-gov-mem.m--open').removeClass('m--open');
        $(this).addClass('m--open');
        var $wrapper = $(this).find('.sp-gov-mem__text-wrapper');
        $wrapper.css('left', - ($(this).position().left + parseInt($(this).css('marginLeft').replace('px','')) + 10));
        $wrapper.css('width', $(this).parents('.governance-container').first().innerWidth() - 20);
        var height = $(this).find('.sp-gov-mem__text-container').outerHeight();
        $wrapper.css('height', height);
      }
    });
    $('.sp-gov-mem__li a').on('click', function(e){
      e.stopPropagation();
    });
  },

  initRetractable: function(){
    $('.retractable').each(function(){
      var $retractable = $(this);
      $retractable.find('.retractable__title').on('click', function(){
        if ($retractable.hasClass('m--open')){
          $retractable.removeClass('m--open');
          $retractable.removeClass('m--open').find('.retractable__container').css('max-height', '');
        }
        else{
          var contentHeight = $retractable.find('.retractable__container-inner').outerHeight(true);
          $retractable.addClass('m--open').find('.retractable__container').css('max-height', (Math.floor(contentHeight) + 20) + 'px');
        }
      });
      if ($retractable.hasClass('m--open')){
        var contentHeight = $retractable.find('.retractable__container-inner').outerHeight(true);
        $retractable.find('.retractable__container').css('max-height', (Math.floor(contentHeight) + 20) + 'px');
      }
    });
  },

  initTAC: function(){
    tarteaucitronForceCDN = '/themes/saipol/assets/tac/';
    tarteaucitronForceLanguage = $('html').attr('lang').substring(0,2) || 'en';
    tarteaucitronForceExpire = 183;

    // Include TAC script
    // $.getScript(tarteaucitronForceCDN + 'tarteaucitron.js',function(){
    //   console.log('loaded');
    // }).done(function(script, status){
    //   // console.log(script);
    //   // console.log(status);
    //   // Initialize TAC
    //   var privacyUrl = '/politique-de-confidentialite';
    //   var cookiesUrl = '/politique-de-cookies';

    //   tarteaucitron.init({
    //     'privacyUrl': privacyUrl,

    //     'hashtag': '#rgpd',
    //     'cookieName': 'rgpd',

    //     'orientation': 'middle',
    //     'showAlertSmall': false,
    //     'cookieslist': true,

    //     'adblocker': false,
    //     'AcceptAllCta': true,
    //     'highPrivacy': true,
    //     'handleBrowserDNTRequest': false,

    //     'removeCredit': true,
    //     'moreInfoLink': true,
    //     'useExternalCss': false,

    //     'readmoreLink': cookiesUrl
    //   });
    //   // console.log(tarteaucitron);
    //   tarteaucitron.job = tarteaucitron.job || [];

    //   // Google Analytics
    //   tarteaucitron.user.gtagUa = 'UA-172826915-1';
    //   // tarteaucitron.user.analyticsMore = function () {};
    //   tarteaucitron.job.push('gtag');
    // }).fail(function(){
    //   console.log('fgail');
    // });

    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.onload = function(){

      var privacyUrl = '/politique-de-cookies';
      var cookiesUrl = '/politique-de-cookies';

      tarteaucitron.init({
        'privacyUrl': privacyUrl,

        'hashtag': '#rgpd',
        'cookieName': 'rgpd',

        'orientation': 'middle',
        'showAlertSmall': false,
        'cookieslist': true,

        'adblocker': false,
        'AcceptAllCta': true,
        'highPrivacy': true,
        'handleBrowserDNTRequest': false,

        'removeCredit': true,
        'moreInfoLink': true,
        'useExternalCss': false,

        'readmoreLink': cookiesUrl
      });
      // console.log(tarteaucitron);
      tarteaucitron.job = tarteaucitron.job || [];

      // Google Analytics
      tarteaucitron.user.gtagUa = 'UA-172826915-1';
      // tarteaucitron.user.analyticsMore = function () {};
      tarteaucitron.job.push('gtag');
    };
    s.src = tarteaucitronForceCDN + 'tarteaucitron.js';
    document.head.appendChild(s);
    // $('head').append(s);

   },

  initGTAG: function(){
    $('[data-gtag]').on('click', function() {
        var $target = $(this);
        var params = $target.data('gtag');

        var action = params.action || 'clic';
        delete params.action;
        var category = params.category || 'clic';
        delete params.category;
        var label =  params.label || '-';
        delete params.label;

        App.gtagEvent(action, category, label);
    });
    $('.js-gtag-oleo100').on('click', function(){
      App.gtagEvent('https://oleo100.com/', 'clic-lien-sortant', 'clic-footer-logo');
    });
    $('.js-gtag-footer a').on('click', function(){
      App.gtagEvent($(this).attr('href'), 'clic-lien-sortant', 'clic-footer');
    });
    $('.js-gtag-avril').on('click', function(){
      App.gtagEvent($(this).attr('href'), 'clic-lien-sortant', 'clic-footer');
    });
    $('.js-gtag-oleomarket').on('click', function(){
      App.gtagEvent('https://www.oleomarket.fr/', 'clic-lien-sortant', 'clic-footer-logo');
    });
    $('.js-gtag-rse').on('click', function(){
      App.gtagEvent('Clic-en-savoir-plus-sur-la-demarche-RSE-du-groupe-avril', 'Clic-CTA-groupe-Avril', 'en-savoir-plus-sur-la-demarche-RSE-du-groupe-avril');
    });
    $('.js-gtag-avril-job').on('click', function(){
      App.gtagEvent('Clic-site-carriere-du-groupe-avril', 'Clic-CTA-groupe-Avril', 'Site-carriere-du-groupe-avril');
    });
    $('.js-gtag-novastell').on('click', function(){
      App.gtagEvent('Clic-decouvrir-l-offre-novastell', 'Clic-CTA-Novastell', 'Decouvrir-l-offre-Novastell');
    });
    $('.js-gtag-lecico').on('click', function(){
      App.gtagEvent('Clic-decouvrir-l-offre-lecico', 'Clic-CTA-Lecico', 'Decouvrir-l-offre-Lecico');
    });
    jQuery(document).on('nfFormSubmitResponse', function(event, response){
      App.gtagEvent('clic-formulaire-contact', 'clic-formulaire-'+response.id, 'clic-formulaire-contact-ok');
      if (response.response.errors.length) return;

      if (response.id == 1) {
        _mtm.push({
          'event': 'form-submission',
          'categorie': 'formulaire',
          'action': 'formulaire-contact',
          'nom': 'formulaire-contact-ok'
        });
      }
    });
  },

  gtagEvent: function(action, category, label){
    if(typeof window.gtag == 'function') {
      window.gtag('event', action, {
        'event_category': category,
        'event_label': label
      });
    }

  }

};
// ---------- APP END

// Init app
$(App.init);
// $(window).load(App.load); // jQuery 2
$(window).on('load', App.load); // jQuery 3
window.App = App;
window._mtm = window._mtm || [];
})(jQuery);

// ---------- MISSING FUNCTIONS
function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
// ---------- MISSING FUNCTIONS END

window.App.config.icon = "M15.938 32c0 0-9.938-14.062-9.938-20.062 0-11.813 9.938-11.938 9.938-11.938s10.062 0.125 10.062 11.875c0 6.187-10.062 20.125-10.062 20.125zM16 6c-2.209 0-4 1.791-4 4s1.791 4 4 4 4-1.791 4-4-1.791-4-4-4z";

window.App.config.mapStyles = [{"elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#dadada"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c9c9c9"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]}];

