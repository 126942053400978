jQuery(function($){
  if (typeof Marionette != "undefined") {
    var wtfFix = Marionette.Object.extend({
      initialize: function() {
        this.listenTo(Backbone.Radio.channel('forms'), 'init:model', this.initEach);
      },

      initEach: function(formModel) {
        var formChannel = 'form-' + formModel.id;
        this.listenTo(Backbone.Radio.channel(formChannel), 'enable:submit', this.submitToButton);
        this.listenTo(Backbone.Radio.channel(formChannel), 'remove:error', this.submitToButton);
      },

      submitToButton: function() {
        $('.nf-form-cont [type=submit]').attr('type', 'button');
      }
    });

    new wtfFix();
  }

  $(document).on('nfFormReady', function(){
    $('.saipol-ab').val($('meta[name=refresh]').attr('content'));
    $('.nf-form-cont [type=submit]').attr('type', 'button');
    $('.nf-form-cont form').attr('novalidate', true);
  });
});
